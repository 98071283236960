import ChangesetView from './ChangesetView';
import { Changeset } from '../api/sentinel';
import WidgetHeader from '../dashboard/common/widget/WidgetHeader';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import { type Sprint } from '../api/sentinel';
import Navbar from '../projectRevisions/navBar/NavBar';

function descending(a: Changeset, b: Changeset) {
    const first = a.changeDate;
    const second = b.changeDate;

    return first == second ? 0 : first > second ? -1 : 1;
}

export default function ChangesetsPanel({
    values,
    sprint,
    projectId,
    projectName,
}: {
    values?: Changeset[];
    projectId?: string;
    projectName?: string;
    sprint?: Partial<Sprint>;
}) {
    const { t } = useTranslation();

    const content = values?.sort(descending).map((value, index) => {
        return <ChangesetView key={index} changeset={value} />;
    });

    return (
        <Paper
            variant="elevation"
            elevation={0}
            sx={{
                borderTopLeftRadius: '20px',
                display: 'grid',
                gridTemplateAreas: '"H"\n"A"\n"B"',
                gridTemplateColumns: '1fr',
                gridTemplateRows: '70px auto',
                overflow: 'hidden',
                height: 'auto',
            }}
        >
            <WidgetHeader
                gridArea={'H'}
                title={t('changesets_title')}
                subtitle={sprint?.name ?? ''}
                trend={undefined}
            />
            <Navbar
                projectName={projectName}
                projectId={projectId}
                sprintId={sprint?.sprintId}
                sprintName={sprint?.name}
            />

            {content}
        </Paper>
    );
}
