import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dateService from "../services/dateService";
import {Changeset} from "../api/sentinel";
import ChangeView from "./Change";
import {Person} from "@microsoft/mgt-react";
import {IDynamicPerson} from "@microsoft/mgt-components";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export default function ChangesetView({changeset}: {
    changeset: Changeset
}) {
    const alteranate: IDynamicPerson = {
        displayName: changeset.changedBy
    }

    return (<Accordion disableGutters={true} defaultExpanded={true}>
        <AccordionSummary sx={{
            marginTop: '1px',
            marginBottom: '1px'
        }} expandIcon={
            <ExpandMoreIcon/>}>
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
            >
                <Typography
                    variant="body2"> {dateService.toStandardDateTimeFormat(changeset.changeDate)} </Typography>
                <Person
                    view="oneline"
                    className="mgt-person"
                    key={changeset.changedBy}
                    personQuery={changeset.changedBy}
                    fallbackDetails={alteranate}
                    personCardInteraction="none"
                    avatarType="photo"
                />

            </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{flexDirection: "column"}}>
            <ChangeView changes={changeset.changes}/>
        </AccordionDetails>
    </Accordion>);
}