import {
    AuthenticationResult,
    InteractionRequiredAuthError,
    IPublicClientApplication,
} from '@azure/msal-browser';
import accessTokenParser, {
    type UserAuthInfoType,
} from '../services/accessTokenParser';
import config from '../services/config';
import { intersect } from '../utils/util';
import { loginRequest } from './authConfig';
import {
    Providers,
    ProviderState,
    SimpleProvider,
} from '@microsoft/mgt-element';

// TODO: This whole module is full of sideEffects and is hard to test. We should consider refactoring/simplifying it

let receivedTokenString: undefined | string;
let userInfo: UserAuthInfoType;
let msalInstance: IPublicClientApplication;

let readerRole: string;
let editorRole: string;
let leaderRole: string;

function setAppRoles() {
    readerRole = config.get('REACT_APP_READER_ROLE_NAME') as string;
    editorRole = config.get('REACT_APP_EDITOR_ROLE_NAME') as string;
    leaderRole = config.get('REACT_APP_LEADER_ROLE_NAME') as string;
}

function setToken(token: string) {
    receivedTokenString = token;
    userInfo = accessTokenParser(receivedTokenString);
}

function isExpired(): boolean {
    return userInfo?.expiration.getTime() <= Date.now() ?? true;
}

function hasReadRights() {
    setAppRoles();
    return hasAnyPermittedRoles([readerRole, editorRole, leaderRole]);
}

function hasEditingRights() {
    setAppRoles();
    return hasAnyPermittedRoles([editorRole, leaderRole]);
}

function hasLeadRights() {
    setAppRoles();
    return hasAnyPermittedRoles([leaderRole]);
}

function hasRole(roleName = '') {
    return hasAnyPermittedRoles([roleName]);
}

function hasAnyPermittedRoles(permittedRoles: string[]) {
    return intersect(permittedRoles, userInfo?.roles ?? []).length > 0;
}

function isAllowed(roles?: string[]) {
    setAppRoles();
    return !roles?.length || hasAnyPermittedRoles(roles);
}

function setMsalInstance(instance: IPublicClientApplication) {
    msalInstance = instance;
}

async function refreshToken(): Promise<string> {
    const result = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: msalInstance.getAllAccounts()[0],
    });

    setToken(result.accessToken);
    return result.accessToken;
}

async function getToken(): Promise<string> {
    if (msalInstance && isExpired()) {
        try {
            return await refreshToken();
        } catch (error: unknown) {
            throw new Error(error as string);
        }
    }
    return receivedTokenString || '';
}

const setGraphToken = async () => {
    const graphRequest = {
        scopes: [config.get('REACT_APP_GRAPH_SCOPES') as string],
        account: msalInstance.getAllAccounts()[0], // Assuming the user is already signed in
    };
    let token: AuthenticationResult;
    try {
        token = await msalInstance.acquireTokenSilent(graphRequest);
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            try {
                token = await msalInstance.acquireTokenPopup(graphRequest);
            } catch (err) {
                console.error(err);
            }
        } else {
            console.error(error);
        }
    } finally {
        Providers.globalProvider = new SimpleProvider(() => {
            return Promise.resolve(token.accessToken);
        });
        Providers.globalProvider.setState(ProviderState.SignedIn);
    }
};

const tokenService = {
    getToken,
    setToken,
    hasRole,
    hasEditingRights,
    hasReadRights,
    hasLeadRights,
    isAllowed,
    isExpired,
    setMsalInstance,
    refreshToken,
    setGraphToken,
};

export default tokenService;
