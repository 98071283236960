// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Change
 */
export interface Change {
    /**
     *
     * @type {string}
     * @memberof Change
     */
    fieldName?: string;
    /**
     *
     * @type {string}
     * @memberof Change
     */
    oldValue?: string;
    /**
     *
     * @type {string}
     * @memberof Change
     */
    newValue?: string;
}

/**
 * Check if a given object implements the Change interface.
 */
export function instanceOfChange(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeFromJSON(json: any): Change {
    return ChangeFromJSONTyped(json, false);
}

export function ChangeFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): Change {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        fieldName: !exists(json, 'fieldName') ? undefined : json['fieldName'],
        oldValue: !exists(json, 'oldValue') ? undefined : json['oldValue'],
        newValue: !exists(json, 'newValue') ? undefined : json['newValue'],
    };
}

export function ChangeToJSON(value?: Change | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        fieldName: value.fieldName,
        oldValue: value.oldValue,
        newValue: value.newValue,
    };
}
