import {
    useController,
    UseControllerReturn,
    useFormContext,
} from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import { labelBuilder } from './labelBuilder';
import { InputProps } from '@mui/material/Input/Input';
import IconTooltip from './IconTooltip';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import theme from '../../theme/theme';

export interface Iprops {
    helper_text?: string;
    label?: string;
    name: string;
    placeholder?: string;
    tooltip_title?: string;
    textAreaStyle?: boolean;
}

const TextFieldInput = (props: Iprops & InputProps) => {
    const { control } = useFormContext();
    const { label, textAreaStyle, ...otherInputProps } = props;

    const controller: UseControllerReturn = useController({
        name: props.name,
        control,
    });
    return (
        <Stack
            alignItems="baseline"
            direction="row"
            justifyContent="flex-start"
            spacing={0}
        >
            <FormControl
                fullWidth
                error={!!controller.fieldState.error}
                margin="normal"
                variant="standard"
            >
                <InputLabel
                    htmlFor={`sentinel-text-input-${controller.field.name}`}
                    id={`sentinel-text-label-${controller.field.name}`}
                    shrink
                >
                    {labelBuilder(label as string, otherInputProps.required)}
                </InputLabel>
                <Input
                    {...otherInputProps}
                    required={false}
                    inputRef={controller.field.ref}
                    id={`sentinel-text-input-${controller.field.name}`}
                    name={controller.field.name}
                    value={controller.field.value}
                    onChange={controller.field.onChange}
                    onBlur={controller.field.onBlur}
                    data-testid={controller.field.name}
                    disableUnderline={textAreaStyle}
                    sx={{
                        ...(textAreaStyle && {
                            border: `solid 1px ${theme.palette.grey[400]}`,
                            borderRadius: 2,
                            position: 'relative',
                            p: '10px',
                            '&.Mui-focused': {
                                border: `solid 2px ${theme.palette.primary.main}`,
                            },
                        }),
                    }}
                />
                {textAreaStyle && props.tooltip_title && (
                    <Box position="absolute" top="-20px" right="-1px">
                        <IconTooltip
                            title={props.tooltip_title}
                            iconName={'helper'}
                        />
                    </Box>
                )}

                {!!controller.fieldState.error && (
                    <FormHelperText>
                        {controller.fieldState.error?.message}
                    </FormHelperText>
                )}
                {!controller.fieldState.error && props.helper_text && (
                    <FormHelperText>{props.helper_text}</FormHelperText>
                )}
            </FormControl>
            {!textAreaStyle && props.tooltip_title && (
                <IconTooltip title={props.tooltip_title} iconName="helper" />
            )}
        </Stack>
    );
};

export default TextFieldInput;
