// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Change } from './Change';
import { ChangeFromJSON, ChangeFromJSONTyped, ChangeToJSON } from './Change';

/**
 *
 * @export
 * @interface Changeset
 */
export interface Changeset {
    /**
     *
     * @type {string}
     * @memberof Changeset
     */
    changedBy: string;
    /**
     *
     * @type {Date}
     * @memberof Changeset
     */
    changeDate: Date;
    /**
     *
     * @type {string}
     * @memberof Changeset
     */
    entityName?: string;
    /**
     *
     * @type {string}
     * @memberof Changeset
     */
    entityType?: string;
    /**
     *
     * @type {Array<Change>}
     * @memberof Changeset
     */
    changes: Array<Change>;
}

/**
 * Check if a given object implements the Changeset interface.
 */
export function instanceOfChangeset(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && 'changedBy' in value;
    isInstance = isInstance && 'changeDate' in value;
    isInstance = isInstance && 'changes' in value;

    return isInstance;
}

export function ChangesetFromJSON(json: any): Changeset {
    return ChangesetFromJSONTyped(json, false);
}

export function ChangesetFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): Changeset {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        changedBy: json['changedBy'],
        changeDate: new Date(json['changeDate']),
        entityName: !exists(json, 'entityName')
            ? undefined
            : json['entityName'],
        entityType: !exists(json, 'entityType')
            ? undefined
            : json['entityType'],
        changes: (json['changes'] as Array<any>).map(ChangeFromJSON),
    };
}

export function ChangesetToJSON(value?: Changeset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        changedBy: value.changedBy,
        changeDate: value.changeDate.toISOString(),
        entityName: value.entityName,
        entityType: value.entityType,
        changes: (value.changes as Array<any>).map(ChangeToJSON),
    };
}
