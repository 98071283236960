import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import theme from '../../theme/theme';
import {useNavigate} from 'react-router-dom';

export default function NavBar({
                                   projectName,
                                   projectId,
                                   sprintId,
                                   sprintName,
                               }: {
    projectName?: string;
    projectId?: string;
    sprintId?: number;
    sprintName?: string;
}) {
    const previousSprint = sprintId !== undefined ? `/${sprintId}` : ``;
    const previousPage = projectId !== undefined ? `/Dashboard/${projectId}${previousSprint}` : '/';
    const navigate = useNavigate();
    const handleBackButtonClick = () => {
        navigate(previousPage);
    };

    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                padding: '1rem 0',
                backgroundColor: theme.palette.common.white,
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <IconButton
                        aria-label={'back_button'}
                        onClick={() => handleBackButtonClick()}
                    >
                        <ArrowBackIcon/>
                    </IconButton>
                    <Typography variant="h1" component="h1" mb="0" ml="10px">
                        {projectName} {sprintName ?? ''}
                    </Typography>
                </Stack>
            </Stack>
        </Container>
    );
}
