import CollectFieldData from './CollectFieldData';
import * as yup from 'yup';
import { caption } from './CaptionTemplate';

const decimalValidation = yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .test('is-decimal', 'The value must be a valid decimal', (_, text) => {
        return (
            text.originalValue == undefined ||
            /^-?[0-9]+(\.[0-9]+)?$|^$/.test(text.originalValue)
        );
    });
const schemas = new Map([
    [
        'INTEGER',
        yup
            .number()
            .integer()
            .transform(value => (isNaN(value) ? undefined : value))
            .test(
                'is-integer',
                'The value must be a valid integer',
                (_, text) => {
                    return (
                        text.originalValue == undefined ||
                        /^-?[0-9]+$|^$/.test(text.originalValue)
                    );
                },
            ),
    ],
    ['DECIMAL', decimalValidation],
    ['PERCENTAGE', decimalValidation],
]);
const functions = [required, minimum, maximum];

function exist(value: any) {
    return typeof value !== 'undefined';
}

function generateFieldValidation(fieldData: CollectFieldData) {
    return new Map([
        [fieldData.dataPoint.id, generateSingleFieldSchema(fieldData)],
    ]);
}

function generateSingleFieldSchema(fieldData: CollectFieldData) {
    const validations = functions
        .map(f => f(fieldData))
        .filter(value => value !== undefined);
    return !Array.isArray(validations) || !validations.length
        ? yup.object()
        : validations.reduce((left, right) =>
              right && left ? right.concat(left) : (right ?? left),
          );
}

function required(fieldData: CollectFieldData): yup.NumberSchema | undefined {
    return fieldData?.dataPoint?.valueConstraint?.required
        ? schemas
              .get(fieldData.dataPoint.type)
              ?.required(
                  fieldData.contraintsCaption.required.replace(
                      '{title}',
                      fieldData.caption.title,
                  ),
              )
        : undefined;
}

function minimum(fieldData: CollectFieldData): yup.NumberSchema | undefined {
    return exist(fieldData?.dataPoint?.valueConstraint?.greaterThanOrEqualTo)
        ? schemas
              .get(fieldData.dataPoint.type)
              ?.min(
                  fieldData.dataPoint.valueConstraint.greaterThanOrEqualTo,
                  caption(fieldData, 'greaterThanOrEqualTo'),
              )
        : undefined;
}

function maximum(fieldData: CollectFieldData): yup.NumberSchema | undefined {
    return exist(fieldData?.dataPoint?.valueConstraint?.lessThanOrEqualTo)
        ? schemas
              .get(fieldData.dataPoint.type)
              ?.max(
                  fieldData.dataPoint.valueConstraint.lessThanOrEqualTo,
                  caption(fieldData, 'lessThanOrEqualTo'),
              )
        : undefined;
}

export default function generateValidations(fieldsData: CollectFieldData[]) {
    const validations = fieldsData.map(field => generateFieldValidation(field));
    return Object.fromEntries(validations.flatMap(map => [...map]));
}
