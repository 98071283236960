import ProjectsList from './projectsList/ProjectsList';
import Dashboard from './dashboard/Dashboard';
import {redirect, RouteObject} from 'react-router-dom';
import Sprint from './sprint/Sprint';
import RequestAccess from './RequestAccess';
import ProtectedRoute from './ProtectedRoute';
import {Layout} from './common/layout';
import Project from './project/Project';
import ReleaseNotes from './releaseNotes/ReleaseNotes';
import ProjectRevisions from './projectRevisions/ProjectRevisions';
import SprintChangeHistory from "./changeset/SprintChangeHistory";

const routes: RouteObject[] = [
    {
        path: '*',
        loader: () => {
            return redirect('/');
        },
    },
    {
        path: '/',
        element: <Layout/>,
        children: [
            {
                path: '',
                element: (
                    <ProtectedRoute>
                        <ProjectsList/>
                    </ProtectedRoute>
                ),
            },
            {
                path: '/RequestAccess',
                element: <RequestAccess/>,
            },
            {
                path: '/Projects',
                element: (
                    <ProtectedRoute>
                        <ProjectsList/>
                    </ProtectedRoute>
                ),
            },
            {
                path: '/Project/:projectId?',
                element: (
                    <ProtectedRoute>
                        <Project/>
                    </ProtectedRoute>
                ),
            },
            {
                path: '/Project/:projectId/revisions',
                element: (
                    <ProtectedRoute>
                        <ProjectRevisions/>
                    </ProtectedRoute>
                ),
            },
            {
                path: '/Project/:projectId/sprint/:sprintId/changeHistory',
                element: (
                    <ProtectedRoute>
                        <SprintChangeHistory/>
                    </ProtectedRoute>
                ),
            },
            {
                path: '/Dashboard/:projectId',
                element: (
                    <ProtectedRoute>
                        <Dashboard/>
                    </ProtectedRoute>
                ),
            },
            {
                path: '/Dashboard/:projectId/:sprintId',
                element: (
                    <ProtectedRoute>
                        <Dashboard/>
                    </ProtectedRoute>
                ),
            },
            {
                path: '/AddSprint/:projectId',
                element: (
                    <ProtectedRoute>
                        <Sprint isCreating/>
                    </ProtectedRoute>
                ),
            },
            {
                path: '/EditSprint/:projectId/:sprintId',
                element: (
                    <ProtectedRoute>
                        <Sprint/>
                    </ProtectedRoute>
                ),
            },
            {
                path: '/AddSprint/:projectId/:sprintId',
                element: (
                    <ProtectedRoute>
                        <Sprint isCreating/>
                    </ProtectedRoute>
                ),
            },
            {
                path: '/ReleaseNotes',
                element: <ReleaseNotes/>,
            },
        ],
    },
];

export default routes;
