import Button from "@mui/material/Button";
import RevisionsIcon from "@mui/icons-material/History";
import {t} from "i18next";
import {MouseEventHandler} from "react";

export default function ChangeHistoryButton({onClick}: { onClick: MouseEventHandler }) {

    return (
        <Button
            startIcon={<RevisionsIcon/>}
            aria-label={t('projectDashboard_seeRevisions_ariaLabel')}
            onClick={onClick}
            size="small"
            color="primary"
        >
            {t('projectDashboard_seeRevisions_link')}
        </Button>
    )

}



