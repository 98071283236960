// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { Metric } from '../models/index';
import { MetricFromJSON, MetricToJSON } from '../models/index';

export interface GetProvidedMetricsForSprintRequest {
    projectId: string;
    sprintId: number;
}

export interface GetProvidedMetricsRevisionsForSprintRequest {
    projectId: string;
    sprintId: number;
}

export interface UpdateProvidedMetricsRequest {
    projectId: string;
    sprintId: number;
    requestBody: { [key: string]: Metric };
}

/**
 *
 */
export class ProvidedMetricsApi extends runtime.BaseAPI {
    /**
     * Get all the provided metrics for the specified sprint
     */
    async getProvidedMetricsForSprintRaw(
        requestParameters: GetProvidedMetricsForSprintRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<{ [key: string]: Metric }>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling getProvidedMetricsForSprint.',
            );
        }

        if (
            requestParameters.sprintId === null ||
            requestParameters.sprintId === undefined
        ) {
            throw new runtime.RequiredError(
                'sprintId',
                'Required parameter requestParameters.sprintId was null or undefined when calling getProvidedMetricsForSprint.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}/sprints/{sprintId}/metrics/provided`
                    .replace(
                        `{${'projectId'}}`,
                        encodeURIComponent(String(requestParameters.projectId)),
                    )
                    .replace(
                        `{${'sprintId'}}`,
                        encodeURIComponent(String(requestParameters.sprintId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            runtime.mapValues(jsonValue, MetricFromJSON),
        );
    }

    /**
     * Get all the provided metrics for the specified sprint
     */
    async getProvidedMetricsForSprint(
        requestParameters: GetProvidedMetricsForSprintRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<{ [key: string]: Metric }> {
        const response = await this.getProvidedMetricsForSprintRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     * Get all the revisions of the provided metrics for the specified sprint
     */
    async getProvidedMetricsRevisionsForSprintRaw(
        requestParameters: GetProvidedMetricsRevisionsForSprintRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<{ [key: string]: Metric }>>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling getProvidedMetricsRevisionsForSprint.',
            );
        }

        if (
            requestParameters.sprintId === null ||
            requestParameters.sprintId === undefined
        ) {
            throw new runtime.RequiredError(
                'sprintId',
                'Required parameter requestParameters.sprintId was null or undefined when calling getProvidedMetricsRevisionsForSprint.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}/sprints/{sprintId}/metrics/provided/revisions`
                    .replace(
                        `{${'projectId'}}`,
                        encodeURIComponent(String(requestParameters.projectId)),
                    )
                    .replace(
                        `{${'sprintId'}}`,
                        encodeURIComponent(String(requestParameters.sprintId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all the revisions of the provided metrics for the specified sprint
     */
    async getProvidedMetricsRevisionsForSprint(
        requestParameters: GetProvidedMetricsRevisionsForSprintRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<{ [key: string]: Metric }>> {
        const response = await this.getProvidedMetricsRevisionsForSprintRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     * Update the required provided metrics for a sprint
     */
    async updateProvidedMetricsRaw(
        requestParameters: UpdateProvidedMetricsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<{ [key: string]: Metric }>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling updateProvidedMetrics.',
            );
        }

        if (
            requestParameters.sprintId === null ||
            requestParameters.sprintId === undefined
        ) {
            throw new runtime.RequiredError(
                'sprintId',
                'Required parameter requestParameters.sprintId was null or undefined when calling updateProvidedMetrics.',
            );
        }

        if (
            requestParameters.requestBody === null ||
            requestParameters.requestBody === undefined
        ) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter requestParameters.requestBody was null or undefined when calling updateProvidedMetrics.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}/sprints/{sprintId}/metrics/provided`
                    .replace(
                        `{${'projectId'}}`,
                        encodeURIComponent(String(requestParameters.projectId)),
                    )
                    .replace(
                        `{${'sprintId'}}`,
                        encodeURIComponent(String(requestParameters.sprintId)),
                    ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.requestBody,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            runtime.mapValues(jsonValue, MetricFromJSON),
        );
    }

    /**
     * Update the required provided metrics for a sprint
     */
    async updateProvidedMetrics(
        requestParameters: UpdateProvidedMetricsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<{ [key: string]: Metric }> {
        const response = await this.updateProvidedMetricsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
