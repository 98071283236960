import {useQuery} from 'react-query';

import {Changeset, sprintApi,} from '../api/sentinel';

type UseSprintChangeHistoryResult = {
    isLoading: boolean;
    error: Error | null;
    data?: Changeset[];
};

const useSprintChangeHistory = (projectId?: string, sprintId?: number): UseSprintChangeHistoryResult => {
    return useQuery<
        Changeset[],
        Error
    >(['sprintChangeHistory', projectId, sprintId], async ({signal}) => {
        return projectId && sprintId
            ? await sprintApi.getSprintChangesets({projectId, sprintId}, {signal})
            : [];
    });
}

export default useSprintChangeHistory;